import React from 'react';
import PropTypes from 'prop-types';

const Notification = ({ message, children }) => (
  <div className="slds-media">
    <span className="slds-icon_container slds-media__figure">
      {children}
    </span>
    <div className="slds-media__body slds-p-vertical_small">
      <h2 className="slds-text-heading_small slds-m-bottom_xx-small">
        {message}
      </h2>
    </div>
  </div>
);

Notification.propTypes = {
  children: PropTypes.element.isRequired,
  message: PropTypes.string.isRequired,
};

export default Notification;
