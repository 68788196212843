import React from 'react';
import PropTypes from 'prop-types';

import { VerticalNavigation } from '@salesforce/design-system-react';

const Sidebar = ({ categories, selectedCategory, selectCategory }) => (
  <div>
    <VerticalNavigation
      id="categories-navigation"
      className="slds-nav-vertical_compact slds-p-top_medium"
      categories={categories}
      selectedId={selectedCategory}
      onSelect={selectCategory}
    />
  </div>
);


Sidebar.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
  })).isRequired,
};

export default Sidebar;
