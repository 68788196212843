import React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import iconType from '../types/icon';
import Notification from './Notification';

const { REACT_APP_URL_PATH } = process.env;

const getUrl = name => `${REACT_APP_URL_PATH}/gallery/icons/${name}.png`;

const element = (record, className) => {
  const url = getUrl(record.name);
  const classNames = `slds-icon ${className}`;
  const iconStyle = {
    backgroundColor: record.bgColor,
  };

  return (
    <div className="clickable slds-icon_container slds-p-around_x-small" style={iconStyle}>
      <img
        src={url}
        alt={record.name}
        className={classNames}
      />
    </div>
  );
};

const Icon = ({ record }) => {
  const url = getUrl(record.name);

  const onCopy = () => toast(
    <Notification message="Icon URL copied to clipboard">
      {element(record, 'slds-icon_medium')}
    </Notification>,
    {
      className: 'notification',
    },
  );

  return (
    <CopyToClipboard text={url} onCopy={onCopy}>
      {element(record, 'slds-icon_large')}
    </CopyToClipboard>
  );
};

Icon.propTypes = {
  record: iconType.isRequired,
};

export default Icon;
