import React from 'react';
import PropTypes from 'prop-types';

import { IconSettings, Button } from '@salesforce/design-system-react';

const Search = ({ value, filter, clear }) => (
  <div className="slds-grid relative">
    { value.length > 0 && (
    <IconSettings iconPath="/styles/icons">
      <Button
        assistiveText={{ icon: 'Clear' }}
        iconCategory="utility"
        iconName="close"
        iconSize="medium"
        iconVariant="bare"
        variant="icon"
        className="btn-search-clear"
        onClick={clear}
      />
    </IconSettings>
    )
    }
    <input
      type="text"
      className="slds-input slds-m-around_xx-small"
      placeholder="Search..."
      value={value}
      onChange={filter}
    />
  </div>
);


Search.defaultProps = {
  value: '',
};

Search.propTypes = {
  filter: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default Search;
