/* polyfills */
import 'react-app-polyfill/ie11';
import 'core-js/fn/array/find';

import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './containers/App';

ReactDOM.render(<App />, document.getElementById('root'));
