const data = [
  {
    name: 'account-fund',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'coin',
      'account',
      'fund',
      'person',
      '3d',
      'user'
    ],
  },
  {
    name: 'account-parameters',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'cog',
      'person',
      'hat',
      'construct',
      'parameters',
      '3d',
      'user'
    ],
  },
  {
    name: 'analyst',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'woman',
      'chart',
      'colours',
      'analyst',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'awards-3d',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'win',
      'cup',
      'trophy',
      'gold',
      'man',
      'award',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'business-3d',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'briefcase',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'construction',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'construct',
      'man',
      'shovel',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'customer-3d',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'colour',
      'customer',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'customer-invalid',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'grey',
      'customer',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'discuss',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'discuss',
      'people',
      'speech',
      'bubble',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'doctor',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'doctor',
      'man',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'document-manage',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'woman',
      'document',
      'card',
      'folder',
      'colour',
      '3d',
      'person',
      'user',
      'file'
    ],
  },
  {
    name: 'editor',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'editor',
      'man',
      'glasses',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'editor-pick',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'editor',
      'man',
      'glasses',
      'flag',
      'red',
      'colour',
      '3d',
      'user',
      'person'
    ],
  },
  {
    name: 'engineer',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'engineer',
      'build',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'experimentations',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'science',
      'man',
      'flask',
      'colour',
      '3d',
      'user',
      'person'
    ],
  },
  {
    name: 'lady-user',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'woman',
      'colour',
      '3d',
      'user',
      'person'
    ],
  },
  {
    name: 'make-friends',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'people',
      'heart',
      'colour',
      '3d',
      'man',
      'person',
      'user'
    ],
  },
  {
    name: 'options',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'woman',
      'computer',
      'colour',
      '3d',
      'user',
      'person'
    ],
  },
  {
    name: 'patient',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'patient',
      'man',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'policeman',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'police',
      'man',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'projects',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'blue',
      'print',
      'engineer',
      'build',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'reviewer',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'colour',
      'person',
      'user'
    ],
  },
  {
    name: 'safe-account',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'safe',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'security-warning',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'alert',
      'police',
      'man',
      'colour',
      '3d',
      'person',
      'use'
    ],
  },
  {
    name: 'service',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'support',
      'service',
      'woman',
      'headphone',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'sportsman',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'sport',
      'man',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'user',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'suit',
      'colour',
      '3d',
      'user',
      'person'
    ],
  },
  {
    name: 'user-favorites',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'star',
      'colour',
      '3d',
      'user',
      'person'
    ],
  },
  {
    name: 'user-informations',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'info',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'users',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'people',
      'colour',
      '3d',
      'man',
      'woman',
      'person',
      'user'
    ],
  },
  {
    name: 'waiter',
    bgColor: '',
    category: '3d_user',
    isPopular: false,
    tags: [
      'man',
      'waiter',
      'colour',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'bear',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'bear',
      'brown',
      'animal',
      '3d',
      'mammal'
    ],
  },
  {
    name: 'bird',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'bird',
      'animal',
      'colour',
      '3d'
    ],
  },
  {
    name: 'butterfly',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'butterfly',
      'blue',
      'insect',
      'animal',
      '3d'
    ],
  },
  {
    name: 'elephant',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'elephant',
      'brown',
      '3d',
      'animal',
      'mammal'
    ],
  },
  {
    name: 'frog',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'frog',
      'green',
      'colour',
      '3d',
      'animal'
    ],
  },
  {
    name: 'hedgehog',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'hedgehog',
      'animal',
      'colour',
      '3d',
      'mammal'
    ],
  },
  {
    name: 'horse',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'horse',
      'donkey',
      'colour',
      '3d',
      'animal',
      'mammal'
    ],
  },
  {
    name: 'panda',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'panda',
      'colour',
      '3d',
      'animal',
      'mammal'
    ],
  },
  {
    name: 'penguin',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'penguin',
      'bird',
      'colour',
      '3d',
      'animal'
    ],
  },
  {
    name: 'swan',
    bgColor: '',
    category: 'animals',
    isPopular: false,
    tags: [
      'stork',
      'bird',
      'colour',
      '3d',
      'animal'
    ],
  },
  {
    name: 'add_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'square',
      'grey',
      'symbol'
    ],
  },
  {
    name: 'add_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'square',
      'orange'
    ],
  },
  {
    name: 'add_2',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'confirm',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'grey',
      'square',
      'OK'
    ],
  },
  {
    name: 'confirm_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'orange',
      'square',
      'OK'
    ],
  },
  {
    name: 'contact',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'contact',
      'phone',
      'receiver',
      'grey'
    ],
  },
  {
    name: 'contact_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'contact',
      'letter',
      'envelope',
      'square',
      'grey',
      'document'
    ],
  },
  {
    name: 'contact_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'contact',
      'letter',
      'envelope',
      'square',
      'orange',
      'document'
    ],
  },
  {
    name: 'css',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'wireless',
      'square',
      'grey',
      'rss',
      'wifi'
    ],
  },
  {
    name: 'css_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'wireless',
      'wifi',
      'square',
      'orange',
      'rss'
    ],
  },
  {
    name: 'favorites',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'star',
      'grey',
      'square',
      'favourite'
    ],
  },
  {
    name: 'favorites_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'star',
      'orange',
      'square',
      'favourite'
    ],
  },
  {
    name: 'favorties',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'star',
      'grey',
      'circle',
      'faviourite'
    ],
  },
  {
    name: 'help',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'help',
      'question',
      'mark',
      'query',
      'square',
      'grey'
    ],
  },
  {
    name: 'help_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'help',
      'question',
      'mark',
      'query',
      'square',
      'orange'
    ],
  },
  {
    name: 'informations',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'info',
      'grey',
      'circle'
    ],
  },
  {
    name: 'location',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'location',
      'flag',
      'square',
      'grey'
    ],
  },
  {
    name: 'location_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'location',
      'flag',
      'square',
      'orange'
    ],
  },
  {
    name: 'music1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'music',
      'grey',
      'circle',
      'note'
    ],
  },
  {
    name: 'ok',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'grey',
      'circle',
      'round',
      'OK'
    ],
  },
  {
    name: 'place',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'location',
      'flag',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'print',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'print',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'protect',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'protect',
      'lock',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'protect_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'protect',
      'lock',
      'square',
      'grey'
    ],
  },
  {
    name: 'protect_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'protect',
      'lock',
      'square',
      'orange'
    ],
  },
  {
    name: 'purchase',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'purchase',
      'shop',
      'cart',
      'circle',
      'round',
      'grey',
      'trolley'
    ],
  },
  {
    name: 'purchase_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'purchase',
      'shop',
      'cart',
      'square',
      'grey',
      'trolley'
    ],
  },
  {
    name: 'purchase_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'purchase',
      'shop',
      'cart',
      'square',
      'orange',
      'trolley'
    ],
  },
  {
    name: 'remove',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'negative',
      'circle',
      'round',
      'grey',
      'remove'
    ],
  },
  {
    name: 'remove_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'negative',
      'square',
      'grey',
      'remove'
    ],
  },
  {
    name: 'remove_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'negative',
      'square',
      'orange',
      'remove'
    ],
  },
  {
    name: 'rss',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'rss',
      'wireless',
      'wifi',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'search',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'view',
      'search',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'search_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'view',
      'search',
      'square',
      'grey'
    ],
  },
  {
    name: 'search_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'view',
      'search',
      'square',
      'orange'
    ],
  },
  {
    name: 'setting_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'setting',
      'cog',
      'square',
      'orange'
    ],
  },
  {
    name: 'setting_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'setting',
      'cog',
      'square',
      'grey'
    ],
  },
  {
    name: 'shutdown',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'shutdown',
      'power',
      'on',
      'off',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'shutdown_0',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'shutdown',
      'power',
      'on',
      'off',
      'square',
      'grey'
    ],
  },
  {
    name: 'shutdown_1',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'shutdown',
      'power',
      'on',
      'off',
      'square',
      'orange'
    ],
  },
  {
    name: 'support',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'help',
      'question',
      'mark',
      'query',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'warning',
    bgColor: '',
    category: 'buttons',
    isPopular: false,
    tags: [
      'alert',
      'warn',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: '100-safe',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'safe',
      'shield',
      'green',
      'protect'
    ],
  },
  {
    name: 'address-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'house',
      'man',
      'address',
      '3d',
      'person',
      'user'
    ],
  },
  {
    name: 'agreement',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'green',
      'star',
      'flash',
      'agree',
      'OK'
    ],
  },
  {
    name: 'air-balloon',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'air',
      'balloon',
      'colour',
      '3d',
      'sport'
    ],
  },
  {
    name: 'all-in-one',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'penknife',
      'swiss',
      'knife',
      'pocket',
      'colour',
      'red',
      'multi'
    ],
  },
  {
    name: 'announcements-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'megaphone',
      'announce',
      'colour',
      'orange'
    ],
  },
  {
    name: 'apple-compter',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'tv',
      'screen',
      'colour',
      'widescreen',
      'apple',
      'computer',
      'mac'
    ],
  },
  {
    name: 'awards-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'award',
      'winner',
      'cup',
      'gold',
      '3d',
      'trophy'
    ],
  },
  {
    name: 'bag',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'bag',
      'grey',
      'cloth',
      'sack'
    ],
  },
  {
    name: 'bardcode-scanner',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'scanner',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'books-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'book',
      'colour',
      '3d',
      'file'
    ],
  },
  {
    name: 'box-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'box',
      'card',
      'board',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'brush-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'paint',
      'brush',
      'colour',
      '3d',
      'brown'
    ],
  },
  {
    name: 'cad',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'CAD',
      'blue',
      'print'
    ],
  },
  {
    name: 'calendar',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'calendar',
      'month',
      'date',
      'colour',
      'agenda',
      'day'
    ],
  },
  {
    name: 'calendar_256',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'calendar',
      'day',
      'date',
      'colour',
      'agenda'
    ],
  },
  {
    name: 'camera-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'slr',
      'camera',
      'colour',
      '3d',
      'black'
    ],
  },
  {
    name: 'chair',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'armchair',
      'chair',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'chat',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'chat',
      'blue',
      '3d'
    ],
  },
  {
    name: 'check',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'work',
      'cone',
      'red',
      '3d'
    ],
  },
  {
    name: 'clock-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'alarm',
      'clock',
      'red',
      '3d',
      'time'
    ],
  },
  {
    name: 'collections-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'heart',
      'red',
      '3d'
    ],
  },
  {
    name: 'compass-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'compass',
      'colour',
      'blue',
      '3d'
    ],
  },
  {
    name: 'confirm_blue',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'blue',
      'shield',
      'OK',
      'colour'
    ],
    '': 'sss'
  },
  {
    name: 'confirm_green',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'green',
      'shield',
      'OK',
      'colour'
    ],
  },
  {
    name: 'contact-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'contact',
      'envelope',
      'red'
    ],
  },
  {
    name: 'cup-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'cup',
      'coffee',
      'tea',
      'colour'
    ],
  },
  {
    name: 'danger',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'danger',
      'cross',
      'red',
      'shield',
      'colour'
    ],
  },
  {
    name: 'discount-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'discount',
      'percent',
      'percentage',
      'red',
      'flash'
    ],
  },
  {
    name: 'document',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'folders',
      'books',
      'colour',
      '3d'
    ],
  },
  {
    name: 'dog',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'dog',
      'grey',
      'colour',
      '3d'
    ],
  },
  {
    name: 'earth-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'world',
      'earth',
      'colour',
      '3d'
    ],
  },
  {
    name: 'easy-to-use',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'wand',
      'magic',
      'colour'
    ],
  },
  {
    name: 'entironment',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'leaf',
      'environment',
      'green',
      'colour'
    ],
  },
  {
    name: 'erase-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'erase',
      'pink',
      'colour'
    ],
  },
  {
    name: 'faqs',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'red',
      'green',
      'colour',
      '3d'
    ],
  },
  {
    name: 'features',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'features',
      'red',
      '3d',
      'study'
    ],
  },
  {
    name: 'fingerprint',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'finger',
      'print',
      'fingerprint'
    ],
  },
  {
    name: 'flag_blue',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'flag',
      'blue'
    ],
  },
  {
    name: 'flag_green',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'flag',
      'green'
    ],
  },
  {
    name: 'flag_red',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'flag',
      'red'
    ],
  },
  {
    name: 'flag_yellow',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'flag',
      'orange'
    ],
  },
  {
    name: 'functions',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'function',
      'shape',
      'triangle',
      'ound',
      'ball',
      'cube',
      '3d'
    ],
  },
  {
    name: 'gallery',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'gallery',
      'painting',
      'photo',
      'frame',
      'apple',
      '3d'
    ],
  },
  {
    name: 'gift-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'present',
      'gift',
      'box',
      'red',
      'ribbon',
      'colour',
      '3d'
    ],
  },
  {
    name: 'gifts',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'present',
      'gift',
      'box',
      'red',
      'ribbon',
      'grey'
    ],
  },
  {
    name: 'gongfu',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'blue',
      'man',
      'martial',
      'art',
      'stick',
      'staff',
      '3d'
    ],
  },
  {
    name: 'good',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'good',
      'peace',
      'hand',
      'finger',
      'yellow',
      '3d'
    ],
  },
  {
    name: 'good-review',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'good',
      'review',
      'speech',
      'bubble',
      'blue',
      'surprise',
      'emoticon',
      'face',
      'colour'
    ],
  },
  {
    name: 'hand',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'hand',
      'glove',
      'white'
    ],
  },
  {
    name: 'history',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'history',
      'clock',
      'time',
      'blue',
      '3d'
    ],
  },
  {
    name: 'hot',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'hot',
      'star',
      'red',
      'colour',
      'flash'
    ],
  },
  {
    name: 'house-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'house',
      'home',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon001',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'wand',
      'magic',
      'colour',
      'star',
      '3d'
    ],
  },
  {
    name: 'icon003',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'funnel',
      'blue'
    ],
  },
  {
    name: 'icon004',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'hammer',
      'tool',
      '3d'
    ],
  },
  {
    name: 'icon005',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'spanner',
      'tool',
      'yellow',
      '3d'
    ],
  },
  {
    name: 'icon007',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pen',
      'pencil',
      'biro',
      'blue'
    ],
  },
  {
    name: 'icon008',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pen',
      'felt',
      'blue'
    ],
  },
  {
    name: 'icon009',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'spade',
      'shovel',
      'tool',
      'yellow',
      '3d'
    ],
  },
  {
    name: 'icon010',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'green'
    ],
  },
  {
    name: 'icon011',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'scissors',
      'cut',
      'blue',
      'colour'
    ],
  },
  {
    name: 'icon014',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'umbrella',
      'sun',
      'parasol',
      'shade',
      'green',
      '3d'
    ],
  },
  {
    name: 'icon016',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'screen',
      'project',
      'colour',
      'stand'
    ],
  },
  {
    name: 'icon017',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'book',
      'brown',
      'colour'
    ],
  },
  {
    name: 'icon018',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'flash',
      'bang',
      'star',
      'yellow',
      'colour'
    ],
  },
  {
    name: 'icon019',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'egg',
      'timer',
      'time',
      'countdown',
      'colour'
    ],
  },
  {
    name: 'icon020',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'lighthouse',
      'beacon',
      'light',
      'colour'
    ],
  },
  {
    name: 'icon021',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'hat',
      'black',
      'tophat',
      'top'
    ],
  },
  {
    name: 'icon022',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'cone',
      'orange',
      'megaphone'
    ],
  },
  {
    name: 'icon023',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'binoculars',
      'black',
      'look'
    ],
  },
  {
    name: 'icon028',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'heart',
      'red',
      '3d'
    ],
  },
  {
    name: 'icon029',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'fund',
      'coins',
      'money',
      'pile',
      'yellow'
    ],
  },
  {
    name: 'icon030',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'world',
      'earth',
      'planet',
      'blue',
      'green',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon031',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pie',
      'chart',
      'colour',
      'analysis',
      'analyse',
      '3d'
    ],
  },
  {
    name: 'icon032',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'reel',
      'film',
      'movie',
      'colour'
    ],
  },
  {
    name: 'icon034',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'communicate',
      'world',
      'earth',
      'planet',
      'blue',
      'colour'
    ],
  },
  {
    name: 'icon035',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'communicate',
      'world',
      'earth',
      'planet',
      'green',
      'colour'
    ],
  },
  {
    name: 'icon036',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speaker',
      'black',
      '3d'
    ],
  },
  {
    name: 'icon037',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'clock',
      'time',
      'dial',
      'needle',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon038',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'clock',
      'time',
      'needle',
      'green',
      '3d'
    ],
  },
  {
    name: 'icon039',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'compact',
      'disc',
      'cd'
    ],
  },
  {
    name: 'icon040',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'shape',
      '3d',
      'cylinder',
      'blue'
    ],
  },
  {
    name: 'icon041',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'shape',
      '3d',
      'cone',
      'blue'
    ],
  },
  {
    name: 'icon042',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'house',
      'home',
      'colour',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon044',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'hammer',
      'gavel',
      'auction',
      'colour',
      'finance'
    ],
  },
  {
    name: 'icon045',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'protect',
      'lock',
      'padlock',
      'close',
      'colour',
      'yellow'
    ],
  },
  {
    name: 'icon046',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'star',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon047',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'star',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon049',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'award',
      'winner',
      'cup',
      'trophy',
      'gold',
      '3d'
    ],
  },
  {
    name: 'icon052',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'lorry',
      'truck',
      'colour',
      'blue',
      '3d',
      'van'
    ],
  },
  {
    name: 'icon053',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'fax',
      'blue',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon054',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'printer',
      'red',
      '3d'
    ],
  },
  {
    name: 'icon055',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'film',
      'cinema',
      'black'
    ],
  },
  {
    name: 'icon057',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'camera',
      'photo',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon058',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speaker',
      'hardware',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon059',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'computer',
      'tower',
      'blue',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon060',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'month',
      'period',
      'calendar',
      'date',
      'colour'
    ],
  },
  {
    name: 'icon061',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'id',
      'card',
      'business',
      'colour'
    ],
  },
  {
    name: 'icon062',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'play',
      'card',
      'heart',
      'ace',
      'colour',
      'red'
    ],
  },
  {
    name: 'icon064',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'certificate',
      'win',
      'prize',
      'colour'
    ],
  },
  {
    name: 'icon067',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'chart',
      'success',
      'profit',
      'arrow',
      'trend',
      'black',
      'increase'
    ],
  },
  {
    name: 'icon068',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'wallet',
      'money',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon069',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'othello',
      'board',
      'game',
      'colour'
    ],
  },
  {
    name: 'icon070',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'box',
      'card',
      'colour'
    ],
  },
  {
    name: 'icon071',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'mail',
      'post',
      'envelope',
      'brown',
      'colour',
      'close'
    ],
  },
  {
    name: 'icon072',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'mail',
      'post',
      'envelope',
      'brown',
      'colour',
      'open'
    ],
  },
  {
    name: 'icon074',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'hammer',
      'gavel',
      'auction',
      'colour',
      'finance',
      'win',
      'star'
    ],
  },
  {
    name: 'icon075',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pig',
      'bank',
      'save',
      'money',
      'colour'
    ],
  },
  {
    name: 'icon076',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'dog',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon077',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'chick',
      'duck',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon078',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'cube',
      'green',
      'blue',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon079',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'fly',
      'insect',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon082',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'rescue',
      'ring',
      'belt',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon083',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'cube',
      'green',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon084',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'dog',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon085',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'fish',
      'gold',
      'orange',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon087',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon089',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'dice',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon090',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'sphere',
      'green',
      'red',
      'orange',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon092',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'music',
      'note',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon098',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'glass',
      'cup',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon099',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'christmas',
      'tree',
      'fir',
      'colour'
    ],
  },
  {
    name: 'icon100',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'tv',
      'wide',
      'screen',
      'colour'
    ],
  },
  {
    name: 'icon101',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'cupboard',
      'book',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon102',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'top',
      'star',
      'flash',
      'colour'
    ],
  },
  {
    name: 'icon103',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'free',
      'star',
      'flash',
      'colour'
    ],
  },
  {
    name: 'icon105',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'briefcase',
      'case',
      'business',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon107',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'phone',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon134',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'yellow',
      'blue',
      'colour',
      '3d'
    ],
  },
  {
    name: 'investigation',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'stats',
      'column',
      'graph',
      'yellow',
      'green',
      'blue',
      'colour'
    ],
  },
  {
    name: 'light-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'light',
      'bulb',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'location-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'location',
      'compass',
      'blue',
      'colour',
      '3d'
    ],
  },
  {
    name: 'location2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'location',
      'world',
      'earth',
      'colour',
      'red',
      'flag',
      '3d'
    ],
  },
  {
    name: 'lock-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'protect',
      'lock',
      'padlock',
      'yellow',
      'colour'
    ],
  },
  {
    name: 'love',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'red',
      'heart',
      '3d'
    ],
  },
  {
    name: 'love-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'bear',
      'toy',
      'brown',
      'purple',
      'colour',
      '3d'
    ],
  },
  {
    name: 'magic_optimization',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'top',
      'hat',
      'magic',
      'wand',
      'black',
      'colour'
    ],
  },
  {
    name: 'mail',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'envelope',
      'email',
      'airmail',
      'post',
      'colour'
    ],
  },
  {
    name: 'mailbox-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'mail',
      'box',
      'blue',
      'colour'
    ],
  },
  {
    name: 'maps',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'map',
      'green',
      'location',
      'colour'
    ],
  },
  {
    name: 'moon',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'moon',
      'grey',
      '3d'
    ],
  },
  {
    name: 'move-phone',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'mobile',
      'phone',
      'blue',
      'cell',
      '3d'
    ],
  },
  {
    name: 'movie',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'film',
      'cinema',
      'colour',
      'blue'
    ],
  },
  {
    name: 'music-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'music',
      'record',
      'colour',
      'black',
      'red'
    ],
  },
  {
    name: 'my-mail-address',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'envelope',
      'mail',
      'post',
      'man',
      'user'
    ],
  },
  {
    name: 'network-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'location',
      'world',
      'earth',
      'colour',
      '3d'
    ],
  },
  {
    name: 'network-3',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'location',
      'world',
      'earth',
      'colour',
      '3d',
      'man',
      'user'
    ],
  },
  {
    name: 'newest-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'new',
      'flash',
      'star',
      'yellow'
    ],
  },
  {
    name: 'no-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'shield',
      'red',
      'colour',
      '3d'
    ],
  },
  {
    name: 'object',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'target',
      'prospect',
      'red',
      'colour'
    ],
  },
  {
    name: 'objects',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'shape',
      '3d',
      'cylinder',
      'blue',
      'sphere',
      'orange'
    ],
  },
  {
    name: 'pack_close',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'box',
      'open',
      'close',
      'card',
      'colour',
      '3d'
    ],
  },
  {
    name: 'pack_open',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'box',
      'open',
      'close',
      'card',
      'colour',
      '3d'
    ],
  },
  {
    name: 'paintbrush',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'paintbrush',
      'brush',
      'colour',
      'red',
      '3d'
    ],
  },
  {
    name: 'palette-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'easel',
      'board',
      'colour'
    ],
  },
  {
    name: 'pencil-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pencil',
      'colour',
      'yellow',
      'erase',
      '3d'
    ],
  },
  {
    name: 'playing-card',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'play',
      'card',
      'spade',
      'ace',
      'black'
    ],
  },
  {
    name: 'plugin',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'jigsaw',
      'plugin',
      'piece',
      'yellow'
    ],
  },
  {
    name: 'popluar',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'medal',
      'award',
      'gold',
      'win',
      'red',
      'blue'
    ],
  },
  {
    name: 'portfolio-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'briefcase',
      'business',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'portfolio-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'briefcase',
      'business',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'post',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'telephone',
      'box',
      'red',
      'colour',
      '3d'
    ],
  },
  {
    name: 'profile',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'briefcase',
      'business',
      'brown',
      'colour'
    ],
  },
  {
    name: 'radar',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'radar',
      'colour'
    ],
  },
  {
    name: 'record',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'cassette',
      'tape',
      'colour'
    ],
  },
  {
    name: 'record-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'cassette',
      'tape',
      'colour'
    ],
  },
  {
    name: 'record-3',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'camera'
    ],
  },
  {
    name: 'register',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'id',
      'card',
      'business',
      'colour'
    ],
  },
  {
    name: 'rescue-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'rescue',
      'life',
      'belt',
      'ring',
      'red',
      'colour',
      '3d'
    ],
  },
  {
    name: 'review-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'blue',
      'review'
    ],
  },
  {
    name: 'rose',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'rose',
      'flower',
      'red',
      'colour'
    ],
  },
  {
    name: 'rss-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'rss',
      'wireless',
      'wifi',
      'square',
      'orange'
    ],
  },
  {
    name: 'run',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'rocket',
      'up',
      'colour',
      '3d'
    ],
  },
  {
    name: 'run-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'fan',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'safe-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'safe',
      'shield',
      'red',
      'yellow',
      'tick',
      'check',
      'colour',
      '3d'
    ],
  },
  {
    name: 'safe-informations',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'safe',
      'shield',
      'red',
      'alert',
      'colour',
      '3d'
    ],
  },
  {
    name: 'sale',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'sale',
      'dollar',
      'tag',
      'shop',
      'red',
      'label'
    ],
  },
  {
    name: 'santa-claus',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'santa',
      'clause',
      'father',
      'christmas',
      'colour',
      '3d'
    ],
  },
  {
    name: 'save-money',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pig',
      'bank',
      'save',
      'money',
      'orange',
      '3d'
    ],
  },
  {
    name: 'school',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'school',
      'building',
      'colour',
      '3d'
    ],
  },
  {
    name: 'screenshot',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'camera',
      'shot',
      'gold',
      'colour',
      '3d'
    ],
  },
  {
    name: 'security',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'umbrella',
      'sun',
      'parasol',
      'shade',
      'green',
      '3d'
    ],
  },
  {
    name: 'security_256',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'protect',
      'lock',
      'padlock',
      'close',
      'colour',
      '3d'
    ],
  },
  {
    name: 'security_blue',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'secure',
      'shield',
      'blue',
      'stripe',
      'colour',
      '3d'
    ],
  },
  {
    name: 'security_green',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'secure',
      'shield',
      'green',
      'stripe',
      'colour',
      '3d'
    ],
  },
  {
    name: 'security_red',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'secure',
      'shield',
      'brown',
      'stripe',
      'colour',
      '3d'
    ],
  },
  {
    name: 'security-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'protect',
      'lock',
      'padlock',
      'close',
      'grey'
    ],
  },
  {
    name: 'shield',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'safe',
      'shield',
      'red',
      'colour',
      '3d'
    ],
  },
  {
    name: 'shoes',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'boots',
      'colour',
      '3d'
    ],
  },
  {
    name: 'shopping',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'basket',
      'shop',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'shot-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'camera',
      'shot',
      'colour'
    ],
  },
  {
    name: 'sound-input',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'microphone',
      'speech',
      'black',
      '3d'
    ],
  },
  {
    name: 'speed',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'speed',
      'dial',
      'meter',
      'colour'
    ],
  },
  {
    name: 'ssl',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'lock',
      'padlock',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'star-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'star',
      'yellow'
    ],
  },
  {
    name: 'statistics',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'stats',
      'column',
      'graph',
      'yellow',
      'pink',
      'green',
      'red',
      'colour',
      '3d'
    ],
  },
  {
    name: 'stop',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'stop',
      'minus',
      'red',
      'sign'
    ],
  },
  {
    name: 'studying',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'study',
      'features',
      'blue',
      '3d'
    ],
  },
  {
    name: 'style-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'style',
      'paint',
      'tube',
      'colour',
      '3d'
    ],
  },
  {
    name: 'succor',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'safe',
      'shield',
      'red',
      'cross',
      'colour',
      '3d'
    ],
  },
  {
    name: 'support-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'support',
      'rescue',
      'life',
      'aid',
      'ring',
      'red',
      'colour'
    ],
  },
  {
    name: 'support-copy',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'support',
      'rescue',
      'life',
      'aid',
      'ring',
      'blue',
      'colour',
      '3d'
    ],
  },
  {
    name: 'taxi',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'taxi',
      'red',
      'colour',
      '3d'
    ],
  },
  {
    name: 'telephone-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'telephone',
      'colour',
      '3d'
    ],
  },
  {
    name: 'temp',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'christmas',
      'candy',
      'cane',
      'red',
      'colour'
    ],
  },
  {
    name: 'temp_64',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'arm',
      'chair',
      'green',
      'colour',
      '3d'
    ],
  },
  {
    name: 'temp-2_64',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'paint',
      'brush',
      'colour',
      '3d'
    ],
  },
  {
    name: 'temp-3_64',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pencil',
      'colour',
      'brown',
      'erase',
      '3d'
    ],
  },
  {
    name: 'temp-4_64',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'pen',
      'ink',
      'black',
      'colour',
      '3d'
    ],
  },
  {
    name: 'temp-5_64',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'paint',
      'brush',
      'colour',
      '3d'
    ],
  },
  {
    name: 'testimonials',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'award',
      'trophy',
      'gold',
      '3d'
    ],
  },
  {
    name: 'text',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'text',
      'board',
      'black',
      'frame',
      'colour'
    ],
  },
  {
    name: 'time',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'history',
      'clock',
      'time',
      'blue',
      '3d'
    ],
  },
  {
    name: 'tools-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'tool',
      'spanner',
      'screwdriver',
      'colour',
      '3d'
    ],
  },
  {
    name: 'traffic',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'traffic',
      'light',
      'red',
      'green',
      'colour',
      '3d'
    ],
  },
  {
    name: 'trial',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'bottle',
      'science',
      'flask',
      'experiment',
      'purple',
      'colour',
      '3d'
    ],
  },
  {
    name: 'user-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'person',
      'profile',
      'black'
    ],
  },
  {
    name: 'video-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'film',
      'cinema',
      'colour',
      'cd',
      '3d'
    ],
  },
  {
    name: 'vip',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'hat',
      'black',
      'red',
      'vip'
    ],
  },
  {
    name: 'waiter-common',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'man',
      'waiter',
      'colour',
      '3d'
    ],
  },
  {
    name: 'washer',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'wash',
      'machine',
      'dish',
      'colour',
      '3d'
    ],
  },
  {
    name: 'washer-2',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'wash',
      'machine',
      'dish',
      'colour',
      '3d'
    ],
  },
  {
    name: 'wheel',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'wheel',
      'tyre',
      'black',
      'colour',
      '3d'
    ],
  },
  {
    name: 'whether-safe',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'safe',
      'shield',
      'red',
      'query',
      'question',
      'colour',
      '3d'
    ],
  },
  {
    name: 'wooden-horse',
    bgColor: '',
    category: 'common',
    isPopular: false,
    tags: [
      'wood',
      'rocking',
      'horse',
      'colour',
      '3d',
      'toy'
    ],
  },
  {
    name: 'auction-finance',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'hammer',
      'gavel',
      'auction',
      'colour',
      'finance'
    ],
  },
  {
    name: 'buy-now',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'buy',
      'colour',
      'circle'
    ],
  },
  {
    name: 'cashier',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'cashier',
      'till',
      'colour',
      '3d'
    ],
  },
  {
    name: 'costing',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'calculator',
      'colour'
    ],
  },
  {
    name: 'credit-card',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'credit',
      'card',
      'pay',
      'colour'
    ],
  },
  {
    name: 'fund-finance',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'fund',
      'finance',
      'coin',
      'money',
      'pile',
      'gold',
      'colour',
      '3d'
    ],
  },
  {
    name: 'guarantee',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'guarantee',
      'safe',
      'grey',
      '3d'
    ],
  },
  {
    name: 'invoice-finance',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'invoice',
      'finance',
      'paid',
      'colour'
    ],
  },
  {
    name: 'market',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'lorry',
      'truck',
      'colour',
      'brown',
      'green',
      '3d'
    ],
  },
  {
    name: 'money-finance',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'cash',
      'money',
      'note',
      'colour'
    ],
  },
  {
    name: 'sale-finance',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'sale',
      'tag',
      'shop',
      'red',
      'label'
    ],
  },
  {
    name: 'shopping-finance',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'shop',
      'bag',
      'colour',
      '3d'
    ],
  },
  {
    name: 'special-offer',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'pig',
      'bank',
      'save',
      'money',
      'orange',
      '3d'
    ],
  },
  {
    name: 'spend-and-expenditure',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'wallet',
      'money',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'stock-finance',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'box',
      'cardboard',
      'brown',
      'colour',
      '3d'
    ],
  },
  {
    name: 'storage',
    bgColor: '',
    category: 'finance',
    isPopular: false,
    tags: [
      'warehouse',
      'store',
      'building',
      'garage',
      'colour',
      '3d'
    ],
  },
  {
    name: 'au',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'australia',
      'flag',
      'colour'
    ],
  },
  {
    name: 'ca',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'canada',
      'canadian',
      'flag',
      'colour'
    ],
  },
  {
    name: 'cn',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'china',
      'flag',
      'colour'
    ],
  },
  {
    name: 'de',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'german',
      'germany',
      'flag',
      'colour'
    ],
  },
  {
    name: 'dk',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'denmark',
      'flag',
      'red',
      'colour'
    ],
  },
  {
    name: 'finland',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'finland',
      'finnish',
      'flag',
      'colour'
    ],
  },
  {
    name: 'fr',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'france',
      'french',
      'flag',
      'red',
      'blue',
      'colour'
    ],
  },
  {
    name: 'holand',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'holland',
      'dutch',
      'flag',
      'colour'
    ],
  },
  {
    name: 'italy',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'italy',
      'italian',
      'flag',
      'colour'
    ],
  },
  {
    name: 'jp',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'japan',
      'flag',
      'colour'
    ],
  },
  {
    name: 'sweden',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'sweden',
      'flag',
      'colour'
    ],
  },
  {
    name: 'switzerland',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'switzerland',
      'flag',
      'colour'
    ],
  },
  {
    name: 'uk',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'union',
      'uk',
      'gb',
      'flag',
      'colour'
    ],
  },
  {
    name: 'us',
    bgColor: '',
    category: 'flags',
    isPopular: false,
    tags: [
      'us',
      'america',
      'flag',
      'colour'
    ],
  },
  {
    name: 'apple',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'apple',
      'fruit',
      'green',
      '3d'
    ],
  },
  {
    name: 'apple-red',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'apple',
      'fruit',
      'red',
      '3d'
    ],
  },
  {
    name: 'banana',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'banana',
      'fruit',
      'bunch',
      'colour',
      'yellow'
    ],
  },
  {
    name: 'banana2',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'banana',
      'fruit',
      'bunch',
      'colour',
      'yellow'
    ],
  },
  {
    name: 'blueberry',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'blueberry',
      'fruit',
      'colour'
    ],
  },
  {
    name: 'chicken',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'chicken',
      'food',
      'colour',
      '3d'
    ],
  },
  {
    name: 'coke',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'bottle',
      'drink',
      'red',
      'coke'
    ],
  },
  {
    name: 'fruitages',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'fruit',
      'apple',
      'banana',
      'orange',
      'grape',
      'blueberry',
      'colour',
      '3d'
    ],
  },
  {
    name: 'grape',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'grapes',
      'bunch',
      'purple',
      'colour',
      '3d'
    ],
  },
  {
    name: 'grape2',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'berry',
      'purple',
      'colour',
      '3d'
    ],
  },
  {
    name: 'hamburger',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'burger',
      'hamburger',
      'food',
      'colour',
      '3d'
    ],
  },
  {
    name: 'hamburger-2',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'burger',
      'hamburger',
      'food',
      'colour',
      '3d'
    ],
  },
  {
    name: 'hot-dog',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'hot',
      'dog',
      'hotdog',
      'food',
      'colour',
      '3d'
    ],
  },
  {
    name: 'orange',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'orange',
      'fruit',
      'colour',
      '3d'
    ],
  },
  {
    name: 'orange-2',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'orange',
      'fruit',
      'colour',
      '3d'
    ],
  },
  {
    name: 'orange-3',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'orange',
      'fruit',
      'colour'
    ],
  },
  {
    name: 'peach',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'peach',
      'fruit',
      'colour',
      '3d'
    ],
  },
  {
    name: 'pear',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'pear',
      'fruit',
      'colour',
      '3d'
    ],
  },
  {
    name: 'pimiento',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'pepper',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'pumpkin',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'pumpkin',
      'halloween',
      'orange',
      'colour',
      '3d'
    ],
  },
  {
    name: 'strawberry',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'strawberry',
      'red',
      'fruit',
      'colour'
    ],
  },
  {
    name: 'tomato',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'tomato',
      'red',
      'colour',
      '3d'
    ],
  },
  {
    name: 'tomato-2',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'tomato',
      'red',
      'colour'
    ],
  },
  {
    name: 'watermelon',
    bgColor: '',
    category: 'food',
    isPopular: false,
    tags: [
      'watermelon',
      'colour',
      '3d'
    ],
  },
  {
    name: 'awards-holiday',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'award',
      'winner',
      'trophy',
      'cup',
      'gold',
      '3d'
    ],
  },
  {
    name: 'ballon-blue',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'balloon',
      'blue',
      '3d'
    ],
  },
  {
    name: 'balloon',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'balloon',
      'orange',
      '3d'
    ],
  },
  {
    name: 'balloon-green',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'balloon',
      'green',
      '3d'
    ],
  },
  {
    name: 'balloon-purple',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'balloon',
      'pink',
      '3d'
    ],
  },
  {
    name: 'balloon-red',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'balloon',
      'red',
      '3d'
    ],
  },
  {
    name: 'balloons',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'balloon',
      'balloons',
      'colour',
      '3d'
    ],
  },
  {
    name: 'birthday-cake',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'birthday',
      'cake',
      'colour'
    ],
  },
  {
    name: 'candy-holiday',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'sweet',
      'candy',
      'red',
      '3d'
    ],
  },
  {
    name: 'christmas-santa',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'santa',
      'father',
      'christmas',
      'colour'
    ],
  },
  {
    name: 'christmas-snowman',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'snowman',
      'snow',
      'man',
      'christmas',
      'colour'
    ],
  },
  {
    name: 'christmas-stick',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'christmas',
      'candy',
      'cane',
      'orange',
      'colour'
    ],
  },
  {
    name: 'christmas-tree',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'tree',
      'christmas',
      'colour'
    ],
  },
  {
    name: 'fire-balloon',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'hot',
      'air',
      'balloon',
      'colour',
      '3d'
    ],
  },
  {
    name: 'gifts-2',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'present',
      'gift',
      'box',
      'red',
      'ribbon',
      'colour',
      '3d'
    ],
  },
  {
    name: 'gifts-holiday',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'present',
      'gift',
      'box',
      'red',
      'bow',
      'ribbon',
      'colour',
      '3d'
    ],
  },
  {
    name: 'halloween-pumpkin',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'pumpkin',
      'halloween',
      'face',
      'orange',
      'colour',
      '3d'
    ],
  },
  {
    name: 'halloween-pumpkin-blank',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'pumpkin',
      'halloween',
      'orange',
      'colour',
      '3d'
    ],
  },
  {
    name: 'love-holiday',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'love',
      'heart',
      'red'
    ],
  },
  {
    name: 'magic-hat',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'top',
      'hat',
      'magic',
      'black',
      'colour'
    ],
  },
  {
    name: 'rose-holiday',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'rose',
      'flower',
      'red',
      'colour'
    ],
  },
  {
    name: 'star-holiday',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'star',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'teddy-bear-holiday',
    bgColor: '',
    category: 'holiday',
    isPopular: false,
    tags: [
      'bear',
      'toy',
      'brown',
      'purple',
      'colour',
      '3d'
    ],
  },
  {
    name: 'ambulance',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'ambulance',
      'colour. 3d'
    ],
  },
  {
    name: 'brain',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'brain',
      'colour'
    ],
  },
  {
    name: 'dna-medical',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'dna',
      'molecule',
      'science',
      'colour'
    ],
  },
  {
    name: 'doctor-medical',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'doctor',
      'man',
      'colour',
      '3d'
    ],
  },
  {
    name: 'first-aid-kit',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'first',
      'aid',
      'kit',
      'box',
      'red',
      '3d'
    ],
  },
  {
    name: 'heart-medical',
    bgColor: '#4995D1',
    category: 'medical',
    isPopular: false,
    tags: [
      'heart',
      'white'
    ],
  },
  {
    name: 'heartbeat',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'heart',
      'red',
      'pulse',
      'beat',
      'heartbeat'
    ],
  },
  {
    name: 'injector',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'inject',
      'med',
      'colour'
    ],
  },
  {
    name: 'medicine-2',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'med',
      'tablet',
      'pill',
      'orange',
      'blue'
    ],
  },
  {
    name: 'medicine-medical',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'med',
      'tablet',
      'pill',
      'red',
      'blue'
    ],
  },
  {
    name: 'microscope',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'microscope',
      'science',
      'colour',
      '3d'
    ],
  },
  {
    name: 'nurse',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'nurse',
      'pink',
      'woman',
      '3d'
    ],
  },
  {
    name: 'stethoscope',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'medical',
      'stethoscope',
      'red',
      'colour'
    ],
  },
  {
    name: 'thermometer',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'hot',
      'thermometer',
      'colour',
      '3d'
    ],
  },
  {
    name: 'wound',
    bgColor: '',
    category: 'medical',
    isPopular: false,
    tags: [
      'plaster',
      'medical',
      'wound',
      'colour'
    ],
  },
  {
    name: 'account_management',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'heart',
      'person',
      'account',
      'management',
      'white'
    ],
  },
  {
    name: 'achievements',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'folder',
      'file',
      'achievement',
      'white'
    ],
  },
  {
    name: 'add-reviews',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'speech',
      'bubble',
      'review',
      'white'
    ],
  },
  {
    name: 'add-to-collections',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'star',
      'white'
    ],
  },
  {
    name: 'alarm-clock',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'alarm',
      'clock',
      'time',
      'white'
    ],
  },
  {
    name: 'alert',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'alert',
      'bell',
      'alarm',
      'white'
    ],
  },
  {
    name: 'analysis',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'pie',
      'chart',
      'white',
      'analysis',
      'analyse'
    ],
  },
  {
    name: 'announcements',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'megaphone',
      'announce',
      'call',
      'white'
    ],
  },
  {
    name: 'auction',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'hammer',
      'gavel',
      'auction',
      'white'
    ],
  },
  {
    name: 'awards',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'winner',
      'cup',
      'trophy',
      'white',
      'award'
    ],
  },
  {
    name: 'awards2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'medal',
      'award',
      'white'
    ],
  },
  {
    name: 'basin',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'bowl',
      'dish',
      'white'
    ],
  },
  {
    name: 'basket',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'basket',
      'shop',
      'white'
    ],
  },
  {
    name: 'basketball',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'basketball',
      'beach',
      'ball',
      'white'
    ],
  },
  {
    name: 'block',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'block',
      'cone',
      'roadwork',
      'white'
    ],
  },
  {
    name: 'book',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'folder',
      'notepad',
      'book',
      'tab',
      'white'
    ],
  },
  {
    name: 'book2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'notepad',
      'notebook',
      'book',
      'white'
    ],
  },
  {
    name: 'books',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'file',
      'filing',
      'book',
      'white'
    ],
  },
  {
    name: 'books2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'book',
      'white'
    ],
  },
  {
    name: 'box',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'box',
      'white'
    ],
  },
  {
    name: 'box2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'open',
      'box',
      'white'
    ],
  },
  {
    name: 'brightess',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'brightness',
      'sun',
      'white'
    ],
  },
  {
    name: 'brush',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'paintbrush',
      'brush',
      'white'
    ],
  },
  {
    name: 'brush-pencil',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'paintbrush',
      'brush',
      'white'
    ],
  },
  {
    name: 'bumbershoot',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'umbrella',
      'sun',
      'shade',
      'parasol',
      'white'
    ],
  },
  {
    name: 'bus',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'bus',
      'carriage',
      'coach',
      'white'
    ],
  },
  {
    name: 'business',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'briefcase',
      'case',
      'business',
      'white'
    ],
  },
  {
    name: 'calculator',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'calculator',
      'white'
    ],
  },
  {
    name: 'call',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'telephone',
      'receiver',
      'call',
      'white'
    ],
  },
  {
    name: 'camera',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'camera',
      'photo',
      'white'
    ],
  },
  {
    name: 'candy',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'sweet',
      'candy',
      'white'
    ],
  },
  {
    name: 'car',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'car',
      'auto',
      'white'
    ],
  },
  {
    name: 'cart',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'cart',
      'shopping',
      'trolley',
      'shop',
      'white'
    ],
  },
  {
    name: 'case',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'case',
      'briefcase',
      'business',
      'white'
    ],
  },
  {
    name: 'celebrate',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'celebrate',
      'balloon',
      'party',
      'white'
    ],
  },
  {
    name: 'certificate',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'certificate',
      'rosette',
      'win',
      'prize',
      'white'
    ],
  },
  {
    name: 'challenges',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'hand',
      'stop',
      'white'
    ],
  },
  {
    name: 'chart',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'pie',
      'chart',
      'statistics',
      'white'
    ],
  },
  {
    name: 'chrismas-crutch',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'christmas',
      'candy',
      'cane',
      'white'
    ],
  },
  {
    name: 'city',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'city',
      'building',
      'white'
    ],
  },
  {
    name: 'clip',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'scissors',
      'clip',
      'cut',
      'white'
    ],
  },
  {
    name: 'cloud',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'cloud',
      'weather',
      'white'
    ],
  },
  {
    name: 'collections',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'star',
      'white'
    ],
  },
  {
    name: 'color-tv',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'tv',
      'wide',
      'screen',
      'computer',
      'white'
    ],
  },
  {
    name: 'comments',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'text',
      'square',
      'white'
    ],
  },
  {
    name: 'computer',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'tower',
      'computer',
      'wide',
      'screen',
      'white'
    ],
  },
  {
    name: 'computer2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'tv',
      'screen',
      'computer',
      'white'
    ],
  },
  {
    name: 'contribution',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'speech',
      'bubbles',
      'chat',
      'white'
    ],
  },
  {
    name: 'cup',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'cup',
      'coffee',
      'tea',
      'drink',
      'white'
    ],
  },
  {
    name: 'cup2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'medal',
      'white'
    ],
  },
  {
    name: 'currency',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'currency',
      'money',
      'dollar',
      'euro',
      'white'
    ],
  },
  {
    name: 'cut',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'scissors',
      'clip',
      'cut',
      'white'
    ],
  },
  {
    name: 'datas',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'left',
      'right',
      'arrow',
      'direct',
      'white'
    ],
  },
  {
    name: 'desk',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'desk',
      'table',
      'white'
    ],
  },
  {
    name: 'diamond',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'diamond',
      'jewel',
      'gem',
      'white'
    ],
  },
  {
    name: 'diploma',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'scroll',
      'paper',
      'white',
      'diploma',
      'certificate'
    ],
  },
  {
    name: 'dna',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'dna',
      'molecule',
      'science',
      'white'
    ],
  },
  {
    name: 'drawer',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'filing',
      'file',
      'drawer',
      'cabinet',
      'white'
    ],
  },
  {
    name: 'earphone',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'headphone',
      'earphone',
      'white'
    ],
  },
  {
    name: 'earth',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'world',
      'earth',
      'planet',
      'white'
    ],
  },
  {
    name: 'edit',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'edit',
      'crayon',
      'pen',
      'text',
      'white'
    ],
  },
  {
    name: 'electronic-organ',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'keyboard',
      'organ',
      'piano',
      'white'
    ],
  },
  {
    name: 'email',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'envelope',
      'email',
      'mail',
      'post',
      'white'
    ],
  },
  {
    name: 'email2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'envelope',
      'email',
      'white'
    ],
  },
  {
    name: 'erase',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'erase',
      'edit',
      'white'
    ],
  },
  {
    name: 'experience',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'speedometer',
      'speed',
      'dial',
      'white'
    ],
  },
  {
    name: 'experiment',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'bottle',
      'science',
      'flask',
      'experiment',
      'white'
    ],
  },
  {
    name: 'film',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'film',
      'cinema',
      'white'
    ],
  },
  {
    name: 'filter',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'filter',
      'funnel',
      'white'
    ],
  },
  {
    name: 'flag',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'flag',
      'white'
    ],
  },
  {
    name: 'flag2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'flag',
      'white'
    ],
  },
  {
    name: 'flag3',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'flag',
      'white'
    ],
  },
  {
    name: 'football',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'football',
      'soccer',
      'ball',
      'white'
    ],
  },
  {
    name: 'fund',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'fund',
      'coin',
      'money',
      'pile',
      'white'
    ],
  },
  {
    name: 'game',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'controller',
      'console',
      'game',
      'play',
      'white'
    ],
  },
  {
    name: 'gift',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'present',
      'gift',
      'box',
      'white'
    ],
  },
  {
    name: 'gift2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'present',
      'gift',
      'box',
      'white'
    ],
  },
  {
    name: 'green',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'leaf',
      'leaves',
      'plant',
      'white'
    ],
  },
  {
    name: 'hardware',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'ipod',
      'white',
      'speaker',
      'hardware'
    ],
  },
  {
    name: 'heart',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'heart',
      'white'
    ],
  },
  {
    name: 'hearts',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'heart',
      'white'
    ],
  },
  {
    name: 'home',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'house',
      'home',
      'white'
    ],
  },
  {
    name: 'home-furnishing',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'home',
      'furnish',
      'lamp',
      'light',
      'white'
    ],
  },
  {
    name: 'home-location',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'house',
      'home',
      'location',
      'pin',
      'white',
      'map'
    ],
  },
  {
    name: 'house',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'house',
      'home',
      'white'
    ],
  },
  {
    name: 'invoice',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'invoice',
      'paper',
      'document',
      'white'
    ],
  },
  {
    name: 'key',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'key',
      'white'
    ],
  },
  {
    name: 'ladybird',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'ladybird',
      'bug',
      'insect',
      'white'
    ],
  },
  {
    name: 'leaderboard',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'leaderboard',
      'win',
      'first',
      'podium',
      'winner',
      'white'
    ],
  },
  {
    name: 'learn',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'learn',
      'tick',
      'check',
      'picture',
      'white'
    ],
  },
  {
    name: 'levels',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'stars',
      'levels',
      'white'
    ],
  },
  {
    name: 'light',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'light',
      'bulb',
      'white'
    ],
  },
  {
    name: 'lock',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'lock',
      'padlock',
      'white'
    ],
  },
  {
    name: 'magic',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'top',
      'hat',
      'magic',
      'white'
    ],
  },
  {
    name: 'mailbox',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'mail',
      'box',
      'white'
    ],
  },
  {
    name: 'manometer',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'dial',
      'circle',
      'round',
      'needle',
      'white'
    ],
  },
  {
    name: 'map',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'map',
      'location',
      'pin',
      'white'
    ],
  },
  {
    name: 'medical-box',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'box',
      'white'
    ],
  },
  {
    name: 'medicine',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'med',
      'tablet',
      'pill',
      'white'
    ],
  },
  {
    name: 'microphone',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'microphone',
      'speech',
      'music',
      'white'
    ],
  },
  {
    name: 'money',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'paper',
      'white'
    ],
  },
  {
    name: 'monitor',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'monitor',
      'screen',
      'tv',
      'white'
    ],
  },
  {
    name: 'monkey',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'monkey',
      'animal',
      'white'
    ],
  },
  {
    name: 'move',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'move',
      'wheel',
      'fast',
      'white'
    ],
  },
  {
    name: 'music',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'headphones',
      'music',
      'white'
    ],
  },
  {
    name: 'music2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'music',
      'note',
      'white'
    ],
  },
  {
    name: 'negative',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'negative',
      'thumb',
      'down',
      'white'
    ],
  },
  {
    name: 'newest',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'new',
      'flash',
      'star',
      'white'
    ],
  },
  {
    name: 'news',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'news',
      'white'
    ],
  },
  {
    name: 'news-paper',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'news',
      'paper',
      'white'
    ],
  },
  {
    name: 'opportunity_closure',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'shake',
      'hand',
      'opportunity',
      'deal',
      'hand',
      'white'
    ],
  },
  {
    name: 'opportunity_management',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'juggle',
      'man',
      'user',
      'ball',
      'white'
    ],
  },
  {
    name: 'palette',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'easel',
      'board',
      'white'
    ],
  },
  {
    name: 'pencil',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'pencil',
      'text',
      'white'
    ],
  },
  {
    name: 'pictures',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'picture',
      'photo',
      'leaf',
      'white'
    ],
  },
  {
    name: 'portfolio',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'portfolio',
      'briefcase',
      'case',
      'white'
    ],
  },
  {
    name: 'positive',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'positive',
      'thumb',
      'up',
      'white'
    ],
  },
  {
    name: 'processing',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'egg',
      'timer',
      'process',
      'white'
    ],
  },
  {
    name: 'project',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'project',
      'helmet',
      'white'
    ],
  },
  {
    name: 'prospecting',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'target',
      'prospect',
      'white'
    ],
  },
  {
    name: 'qualification',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'qualification',
      'weigh',
      'equal',
      'white'
    ],
  },
  {
    name: 'rescue',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'rescue',
      'life',
      'belt',
      'ring',
      'white'
    ],
  },
  {
    name: 'review',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'think',
      'white'
    ],
  },
  {
    name: 'rewards',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'reward',
      'rosette',
      'tick',
      'check',
      'white'
    ],
  },
  {
    name: 'rewards_generic',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'reward',
      'rosette',
      'white'
    ],
  },
  {
    name: 'rocketship',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'rocketship',
      'rocket',
      'up',
      'white'
    ],
  },
  {
    name: 'safe',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'safe',
      'box',
      'white'
    ],
  },
  {
    name: 'satellite',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'satellite',
      'white'
    ],
  },
  {
    name: 'save',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'pig',
      'bank',
      'save',
      'money',
      'white'
    ],
  },
  {
    name: 'send',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'send',
      'mail',
      'white'
    ],
  },
  {
    name: 'shipping',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'bag',
      'shopping',
      'white'
    ],
  },
  {
    name: 'shop',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'shop',
      'stall',
      'white',
      'market'
    ],
  },
  {
    name: 'shot',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'camera',
      'photo',
      'white'
    ],
  },
  {
    name: 'star',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'star',
      'white'
    ],
  },
  {
    name: 'start',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'rocket',
      'up',
      'white'
    ],
  },
  {
    name: 'states',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'stats',
      'column',
      'graph',
      'white'
    ],
  },
  {
    name: 'stock',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'chart',
      'success',
      'profit',
      'arrow',
      'trend',
      'white',
      'increase'
    ],
  },
  {
    name: 'style',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'wand',
      'magic',
      'star',
      'white'
    ],
  },
  {
    name: 'tape',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'tape',
      'music',
      'white'
    ],
  },
  {
    name: 'team',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'users',
      'people',
      'team',
      'white'
    ],
  },
  {
    name: 'teddy-bear',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'bear',
      'toy',
      'white'
    ],
  },
  {
    name: 'telephone',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'phone',
      'white'
    ],
  },
  {
    name: 'telephone2',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'white'
    ],
  },
  {
    name: 'telescope',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'binoculars',
      'view',
      'see',
      'look',
      'white'
    ],
  },
  {
    name: 'temperature',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'temperature',
      'thermometer',
      'heat',
      'white'
    ],
  },
  {
    name: 'theme',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'shirt'
    ],
  },
  {
    name: 'time_period',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'month',
      'time',
      'period',
      'calendar',
      'date',
      'white'
    ],
  },
  {
    name: 'tools',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'tool',
      'pen',
      'knife',
      'multi',
      'white'
    ],
  },
  {
    name: 'train',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'train',
      'white'
    ],
  },
  {
    name: 'transfer',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'lorry',
      'truck',
      'white',
      'van'
    ],
  },
  {
    name: 'unlock',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'lock',
      'padlock',
      'open',
      'white'
    ],
  },
  {
    name: 'video',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'film',
      'video',
      'white'
    ],
  },
  {
    name: 'visit',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: true,
    tags: [
      'chart',
      'success',
      'profit',
      'arrow',
      'trend',
      'white',
      'up'
    ],
  },
  {
    name: 'weather',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'weather',
      'cloud',
      'rainbow',
      'white'
    ],
  },
  {
    name: 'pen',
    bgColor: '#4995D1',
    category: 'metro',
    isPopular: false,
    tags: [
      'pen',
      'text',
      'white'
    ],
  },
  {
    name: 'add',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'basket2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'basket',
      'shopping',
      'white',
      'circle'
    ],
  },
  {
    name: 'call2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'telephone',
      'receiver',
      'white'
    ],
  },
  {
    name: 'call3',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'telephone',
      'receiver',
      'white'
    ],
  },
  {
    name: 'clock',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'clock',
      'time',
      'white'
    ],
  },
  {
    name: 'compass',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'compass',
      'white'
    ],
  },
  {
    name: 'computer3',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'tv',
      'circle',
      'round',
      'screen',
      'white'
    ],
  },
  {
    name: 'customer',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'person',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'detection',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'radar',
      'white'
    ],
  },
  {
    name: 'directions',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'compass',
      'needle',
      'white',
      'circle'
    ],
  },
  {
    name: 'directory',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'circle',
      'round',
      'square',
      'white'
    ],
  },
  {
    name: 'discount',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'percent',
      'percentage',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'earth2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'world',
      'earth',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'email3',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'envelope',
      'email',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'flag4',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'flag',
      'white',
      'circle'
    ],
  },
  {
    name: 'fund2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'fund',
      'money',
      'coins',
      'pile',
      'white',
      'circle'
    ],
  },
  {
    name: 'home2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'house',
      'home',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'lock2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'lock',
      'padlock',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'music3',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'music',
      'note',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'plane',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'plane',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'power',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'power',
      'flash',
      'lightning',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'repair',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'fix',
      'tool',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'review2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'satisfied',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'satisfied',
      'smile',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'send2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'send',
      'mail',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'setting',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'setting',
      'cog',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'sport',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'sport',
      'ball',
      'white',
      'circle',
      'round'
    ],
  },
  {
    name: 'star2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'star',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'telephone3',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'theme2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'theme',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'video2',
    bgColor: '#026EA0',
    category: 'metro_rounded',
    isPopular: false,
    tags: [
      'video',
      'camera',
      'circle',
      'round',
      'white',
      'movie'
    ],
  },
  {
    name: 'add-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'air-mode-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'air',
      'mode',
      'cloud',
      'outline',
      'grey'
    ],
  },
  {
    name: 'alarm-a-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'alarm',
      'bell',
      'outline',
      'grey'
    ],
  },
  {
    name: 'alarm-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'alarm',
      'siren',
      'grey',
      'light'
    ],
  },
  {
    name: 'attachment-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'attach',
      'clip',
      'grey'
    ],
  },
  {
    name: 'attachment-delete-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'attach',
      'delete',
      'cross',
      'clip',
      'grey'
    ],
  },
  {
    name: 'awards-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'ribbon',
      'grey',
      'rosette',
      'award'
    ],
  },
  {
    name: 'bluetooth-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'bluetooth',
      'grey'
    ],
  },
  {
    name: 'calendar-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'calendar',
      'day',
      'date',
      'white'
    ],
  },
  {
    name: 'call-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'telephone',
      'receiver',
      'grey'
    ],
  },
  {
    name: 'camera-a-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'camera',
      'grey',
      'outline'
    ],
  },
  {
    name: 'camera-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'camera',
      'grey'
    ],
  },
  {
    name: 'car-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'car',
      'grey',
      'outline'
    ],
  },
  {
    name: 'chat-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'chat',
      'grey'
    ],
  },
  {
    name: 'chat-a-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'speech',
      'bubbles',
      'chat',
      'grey',
      'writing'
    ],
  },
  {
    name: 'chat-offline-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'speech',
      'bubbles',
      'chat',
      'grey',
      'cross',
      'stop'
    ],
  },
  {
    name: 'chat-online-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'speech',
      'bubbles',
      'chat',
      'grey',
      'tick',
      'check'
    ],
  },
  {
    name: 'check-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'minus',
      'negative',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'clock-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'time',
      'grey'
    ],
  },
  {
    name: 'clock-alarm-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'alarm',
      'clock',
      'white',
      'outline'
    ],
  },
  {
    name: 'close-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'cross',
      'grey',
      'circle'
    ],
  },
  {
    name: 'config-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'cog',
      'configure',
      'grey',
      'outline'
    ],
  },
  {
    name: 'creditcard-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'credit',
      'card',
      'pay',
      'grey'
    ],
  },
  {
    name: 'delete-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'cross',
      'check',
      'grey',
      'circle'
    ],
  },
  {
    name: 'document-edit-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'pencil',
      'document',
      'edit',
      'grey',
      'outline'
    ],
  },
  {
    name: 'document-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'document',
      'grey',
      'outline'
    ],
  },
  {
    name: 'earphone-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'headphone',
      'grey'
    ],
  },
  {
    name: 'earth-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'world',
      'grey'
    ],
  },
  {
    name: 'email-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'envelope',
      'email',
      'grey'
    ],
  },
  {
    name: 'favorites-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'star',
      'grey',
      'favourite'
    ],
  },
  {
    name: 'first-aid-kit-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'first',
      'aid',
      'kit',
      'box',
      'grey'
    ],
  },
  {
    name: 'gift-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'present',
      'gift',
      'box',
      'grey'
    ],
  },
  {
    name: 'help-a-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'help',
      'question',
      'mark',
      'query',
      'grey'
    ],
  },
  {
    name: 'help-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'help',
      'question',
      'mark',
      'query',
      'grey',
      'circle'
    ],
  },
  {
    name: 'home-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'house',
      'grey'
    ],
  },
  {
    name: 'hot-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'hot',
      'star',
      'grey',
      'flash'
    ],
  },
  {
    name: 'information-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'information-a-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'reply',
      'text',
      'speech',
      'bubble',
      'grey'
    ],
  },
  {
    name: 'key-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'key',
      'grey',
      'outline'
    ],
  },
  {
    name: 'keyboard-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'keyboard',
      'grey',
      'outline'
    ],
  },
  {
    name: 'loading-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'load',
      'progress',
      'grey'
    ],
  },
  {
    name: 'lock-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'lock',
      'padlock',
      'grey'
    ],
  },
  {
    name: 'love-a-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'heart',
      'grey'
    ],
  },
  {
    name: 'love-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'heart',
      'grey',
      'outline'
    ],
  },
  {
    name: 'mike-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'microphone',
      'speech',
      'music',
      'grey'
    ],
  },
  {
    name: 'movie-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'reel',
      'film',
      'movie',
      'grey'
    ],
  },
  {
    name: 'music-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'music',
      'note',
      'grey'
    ],
  },
  {
    name: 'network-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'network',
      'world',
      'grey'
    ],
  },
  {
    name: 'notebook-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'laptop',
      'grey',
      'outline'
    ],
  },
  {
    name: 'notes-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'note',
      'page',
      'grey'
    ],
  },
  {
    name: 'open-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'open',
      'folder',
      'grey'
    ],
  },
  {
    name: 'permission-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'tick',
      'check',
      'flash',
      'grey'
    ],
  },
  {
    name: 'print-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'print',
      'grey'
    ],
  },
  {
    name: 'profile-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'user',
      'profile',
      'grey',
      'outline'
    ],
  },
  {
    name: 'record-a-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'microphone',
      'speech',
      'grey'
    ],
  },
  {
    name: 'record-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'film',
      'record',
      'grey'
    ],
  },
  {
    name: 'reply-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'reply',
      'writing',
      'speech',
      'bubble',
      'grey'
    ],
  },
  {
    name: 'report-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'pie',
      'chart',
      'circle',
      'round',
      'grey'
    ],
  },
  {
    name: 'reviews-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'grey'
    ],
  },
  {
    name: 'rotate_0-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'rotate',
      'arrow',
      'grey'
    ],
  },
  {
    name: 'sale-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'sale',
      'dollar',
      'tag',
      'shop',
      'grey',
      'label'
    ],
  },
  {
    name: 'search-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'search',
      'magnify',
      'grey'
    ],
  },
  {
    name: 'security-a-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'security',
      'shield',
      'grey'
    ],
  },
  {
    name: 'security-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'security',
      'lock',
      'tick',
      'grey'
    ],
  },
  {
    name: 'server-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'server',
      'computer',
      'grey'
    ],
  },
  {
    name: 'shutdown-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'power',
      'on',
      'off',
      'shutdown',
      'grey'
    ],
  },
  {
    name: 'signal-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'signal',
      'antenna',
      'grey'
    ],
  },
  {
    name: 'statistics-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'chart',
      'success',
      'profit',
      'arrow',
      'trend',
      'grey',
      'increase'
    ],
  },
  {
    name: 'tag-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'flag',
      'outline',
      'grey'
    ],
  },
  {
    name: 'team-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'user',
      'profile',
      'grey',
      'outline'
    ],
  },
  {
    name: 'tel-2-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'mobile',
      'phone',
      'cell',
      'grey'
    ],
  },
  {
    name: 'text-document-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'pencil',
      'document',
      'text',
      'grey',
      'outline'
    ],
  },
  {
    name: 'trash-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'trash',
      'grey'
    ],
  },
  {
    name: 'upgrade-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'network',
      'world',
      'arrow',
      'grey',
      'upload'
    ],
  },
  {
    name: 'vendue-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'hammer',
      'gavel',
      'auction',
      'grey'
    ],
  },
  {
    name: 'warning-a-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'triangle',
      'grey',
      'outline'
    ],
  },
  {
    name: 'warning-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'triangle',
      'grey'
    ],
  },
  {
    name: 'wireless-iphone',
    bgColor: '#00476D',
    category: 'phone',
    isPopular: false,
    tags: [
      'laptop',
      'grey',
      'wireless',
      'wifi',
      'outline'
    ],
  },
  {
    name: 'profile-01',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'house',
      'orange'
    ],
  },
  {
    name: 'profile-02',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'lock',
      'orange'
    ],
  },
  {
    name: 'profile-02_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'lock',
      'white'
    ],
  },
  {
    name: 'profile-03',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'circle',
      'round',
      'orange'
    ],
  },
  {
    name: 'profile-03_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'profile-04',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'network',
      'world',
      'orange'
    ],
  },
  {
    name: 'profile-04_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'lock',
      'padlock',
      'tick',
      'white'
    ],
  },
  {
    name: 'profile-05',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'circle',
      'round',
      'orange',
      'spanish'
    ],
  },
  {
    name: 'profile-05_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'search',
      'magnify',
      'white'
    ],
  },
  {
    name: 'profile-06',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'pie',
      'chart',
      'orange',
      'black',
      'stats'
    ],
  },
  {
    name: 'profile-06_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'microphone',
      'speech',
      'white'
    ],
  },
  {
    name: 'profile-07',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'network',
      'world',
      'arrow',
      'orange',
      'black',
      'download'
    ],
  },
  {
    name: 'profile-07_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'music',
      'note',
      'white'
    ],
  },
  {
    name: 'profile-08',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'network',
      'world',
      'arrow',
      'orange',
      'black',
      'upload'
    ],
  },
  {
    name: 'profile-08_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'triangle',
      'white'
    ],
  },
  {
    name: 'profile-09',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'square',
      'orange',
      'text'
    ],
  },
  {
    name: 'profile-09_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'plus',
      'add',
      'cross',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'profile-10',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'square',
      'orange',
      'black',
      'text'
    ],
  },
  {
    name: 'profile-10_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'power',
      'on',
      'off',
      'shutdown',
      'white'
    ],
  },
  {
    name: 'profile-11',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'calendar',
      'month',
      'date',
      'orange'
    ],
  },
  {
    name: 'profile-11_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'wrong',
      'cross',
      'circle',
      'round',
      'white'
    ],
  },
  {
    name: 'profile-12',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'document',
      'folder',
      'arrow',
      'download',
      'open',
      'orange'
    ],
  },
  {
    name: 'profile-12_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'help',
      'question',
      'mark',
      'query',
      'white',
      'circle'
    ],
  },
  {
    name: 'profile-13',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'case',
      'first',
      'aid',
      'plus',
      'orange'
    ],
  },
  {
    name: 'profile-13_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'clock',
      'white'
    ],
  },
  {
    name: 'profile-14',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'triangle',
      'orange'
    ],
  },
  {
    name: 'profile-14_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'reel',
      'film',
      'movie',
      'white'
    ],
  },
  {
    name: 'profile-15',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'contact',
      'letter',
      'envelope',
      'orange'
    ],
  },
  {
    name: 'profile-15_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'world',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-16',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'power',
      'battery',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-16_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'clock',
      'alarm',
      'white'
    ],
  },
  {
    name: 'profile-17',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'lock',
      'padlock',
      'tick',
      'orange'
    ],
  },
  {
    name: 'profile-17_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'network',
      'world',
      'white'
    ],
  },
  {
    name: 'profile-18',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'hammer',
      'gavel',
      'auction',
      'orange'
    ],
  },
  {
    name: 'profile-18_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'circle',
      'round',
      'white',
      'spanish'
    ],
  },
  {
    name: 'profile-19',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'alarm',
      'siren',
      'orange',
      'light'
    ],
  },
  {
    name: 'profile-19_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'pie',
      'chart',
      'stats',
      'grey'
    ],
  },
  {
    name: 'profile-20',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'video',
      'camera',
      'orange',
      'black',
      'movie'
    ],
  },
  {
    name: 'profile-20_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'network',
      'world',
      'arrow',
      'grey',
      'download'
    ],
  },
  {
    name: 'profile-21',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'heart',
      'orange',
      'outline'
    ],
  },
  {
    name: 'profile-21_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'network',
      'world',
      'arrow',
      'grey',
      'upload'
    ],
  },
  {
    name: 'profile-22',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'search',
      'magnify',
      'orange'
    ],
  },
  {
    name: 'profile-22_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'text',
      'square',
      'white'
    ],
  },
  {
    name: 'profile-23',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'search',
      'magnify',
      'orange',
      '3d'
    ],
  },
  {
    name: 'profile-23_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'text',
      'square'
    ],
  },
  {
    name: 'profile-24',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'microphone',
      'speech',
      'orange'
    ],
  },
  {
    name: 'profile-24_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'house',
      'search',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-25',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'note',
      'music',
      'orange'
    ],
  },
  {
    name: 'profile-25_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'house',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-26',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'bell',
      'alarm',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-26_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-27',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'key',
      'orange',
      'outline'
    ],
  },
  {
    name: 'profile-27_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white',
      'search'
    ],
  },
  {
    name: 'profile-28',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'info',
      'warning',
      'triangle',
      'orange',
      'black',
      'outline'
    ],
  },
  {
    name: 'profile-28_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white',
      'power'
    ],
  },
  {
    name: 'profile-29',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'load',
      'progress'
    ],
  },
  {
    name: 'profile-29_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white',
      'time'
    ],
  },
  {
    name: 'profile-30',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white',
      'info'
    ],
  },
  {
    name: 'profile-31',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'power',
      'on',
      'off',
      'shutdown',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-31_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white',
      'lock'
    ],
  },
  {
    name: 'profile-32',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'cross',
      'orange',
      'black',
      'circle'
    ],
  },
  {
    name: 'profile-32_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white',
      'warn'
    ],
  },
  {
    name: 'profile-33',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'help',
      'question',
      'mark',
      'query',
      'orange',
      'black',
      'circle'
    ],
  },
  {
    name: 'profile-33_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'white',
      'shield',
      'protect'
    ],
  },
  {
    name: 'profile-34',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'user',
      'profile',
      'orange',
      'outline'
    ],
  },
  {
    name: 'profile-34_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'white'
    ],
  },
  {
    name: 'profile-35',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'user',
      'profile',
      'orange',
      'black',
      'outline'
    ],
  },
  {
    name: 'profile-35_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'paper',
      'document',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-36',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'user',
      'profile',
      'orange',
      'black',
      'pen',
      'outline'
    ],
  },
  {
    name: 'profile-36_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'document',
      'pen',
      'edit',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-37',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'text',
      'black',
      'red',
      'orange',
      'yellow'
    ],
  },
  {
    name: 'profile-37_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'document',
      'text',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-38',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'text',
      'black',
      'red',
      'orange',
      'yellow',
      'grey'
    ],
  },
  {
    name: 'profile-38_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'contact',
      'letter',
      'envelope',
      'white'
    ],
  },
  {
    name: 'profile-39',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'cross',
      'black',
      'red',
      'reply',
      'grey'
    ],
  },
  {
    name: 'profile-40',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'black',
      'grey'
    ],
  },
  {
    name: 'profile-40_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'envelope',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-41',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'keyboard',
      'orange',
      'outline'
    ],
  },
  {
    name: 'profile-41_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'cog',
      'configure',
      'white',
      'outline'
    ],
  },
  {
    name: 'profile-42',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'time',
      'grey',
      'circle',
      'round',
      'orange'
    ],
  },
  {
    name: 'profile-42_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'flag',
      'white',
      'outline'
    ],
  },
  {
    name: 'profile-43',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'search',
      'magnify',
      'white'
    ],
  },
  {
    name: 'profile-44',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'world',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-44_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'basket',
      'shopping',
      'white'
    ],
  },
  {
    name: 'profile-45',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'clock',
      'alarm',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-45_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'telephone',
      'receiver',
      'white'
    ],
  },
  {
    name: 'profile-46',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'air',
      'mode',
      'cloud',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-46_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'document',
      'folder',
      'arrow',
      'download',
      'open',
      'white'
    ],
  },
  {
    name: 'profile-47',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'attach',
      'clip',
      'orange'
    ],
  },
  {
    name: 'profile-47_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'case',
      'first',
      'aid',
      'plus',
      'white'
    ],
  },
  {
    name: 'profile-48',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'attach',
      'delete',
      'cross',
      'clip',
      'orange',
      'red'
    ],
  },
  {
    name: 'profile-48_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'warning',
      'triangle',
      'white'
    ],
  },
  {
    name: 'profile-49',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-49_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'shield',
      'protect',
      'white'
    ],
  },
  {
    name: 'profile-50',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'laptop',
      'wireless',
      'wifi',
      'outline',
      'orange',
      'red',
      'black'
    ],
  },
  {
    name: 'profile-50_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'signal',
      'antenna',
      'white'
    ],
  },
  {
    name: 'profile-51',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'laptop',
      'load',
      'progress',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-51_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'headphone',
      'white'
    ],
  },
  {
    name: 'profile-52',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'laptop',
      'microphone',
      'speech',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-52_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'camera',
      'white'
    ],
  },
  {
    name: 'profile-53',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'car',
      'orange'
    ],
  },
  {
    name: 'profile-53_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'alarm',
      'siren',
      'white',
      'light'
    ],
  },
  {
    name: 'profile-54',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'car',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-54_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'calendar',
      'date',
      'white'
    ],
  },
  {
    name: 'profile-55',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'house',
      'search',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-55_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'camera',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-56',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'house',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-56_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'boat',
      'white'
    ],
  },
  {
    name: 'profile-57',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'laptop',
      'screen',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-57_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'key',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-58',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black',
      'search'
    ],
  },
  {
    name: 'profile-58_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'air',
      'mode',
      'cloud',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-59',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black',
      'power'
    ],
  },
  {
    name: 'profile-59_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'bell',
      'alarm',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-60',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black',
      'time'
    ],
  },
  {
    name: 'profile-61',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black',
      'info'
    ],
  },
  {
    name: 'profile-61_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'attach',
      'clip',
      'white'
    ],
  },
  {
    name: 'profile-62',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black',
      'lock'
    ],
  },
  {
    name: 'profile-62_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'attach',
      'delete',
      'cross',
      'clip',
      'white'
    ],
  },
  {
    name: 'profile-63',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black',
      'warn'
    ],
  },
  {
    name: 'profile-63_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'user',
      'white',
      'outline'
    ],
  },
  {
    name: 'profile-64',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'outline',
      'orange',
      'black',
      'shield',
      'protect'
    ],
  },
  {
    name: 'profile-64_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'user',
      'white',
      'outline'
    ],
  },
  {
    name: 'profile-65',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'screen',
      'orange'
    ],
  },
  {
    name: 'profile-65_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'user',
      'white',
      'outline',
      'pen'
    ],
  },
  {
    name: 'profile-66',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'document',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-66_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'text',
      'white'
    ],
  },
  {
    name: 'profile-67',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'document',
      'pen',
      'edit',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-67_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'text',
      'white'
    ],
  },
  {
    name: 'profile-68',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'document',
      'text',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-68_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'cross',
      'white'
    ],
  },
  {
    name: 'profile-69',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'envelope',
      'mail',
      'orange',
      'outline'
    ],
  },
  {
    name: 'profile-69_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'speech',
      'bubble',
      'tick',
      'white'
    ],
  },
  {
    name: 'profile-70',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'envelope',
      'mail',
      'orange',
      'outline'
    ],
  },
  {
    name: 'profile-70_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'load',
      'progress',
      'white'
    ],
  },
  {
    name: 'profile-71',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'setting',
      'cog',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-71_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'keyboard',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-72',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'flag',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-72_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'hammer',
      'gavel',
      'auction',
      'white'
    ],
  },
  {
    name: 'profile-73',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'basket',
      'shopping',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-73_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'light',
      'bulb',
      'white'
    ],
  },
  {
    name: 'profile-74',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'orange'
    ],
  },
  {
    name: 'profile-74_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'battery',
      'power',
      'white'
    ],
  },
  {
    name: 'profile-75',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'shield',
      'protect',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-75_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'video',
      'camera',
      'movie',
      'white'
    ],
  },
  {
    name: 'profile-76',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'signal',
      'antenna',
      'orange'
    ],
  },
  {
    name: 'profile-76_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'light',
      'bulb',
      'white',
      'outline'
    ],
  },
  {
    name: 'profile-77',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'headphones',
      'music',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-77_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'print',
      'white'
    ],
  },
  {
    name: 'profile-78',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'camera',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-78_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'tv',
      'outline',
      'white',
      'laptop'
    ],
  },
  {
    name: 'profile-79',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'camera',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-79_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'laptop',
      'white',
      'wireless',
      'wifi',
      'outline'
    ],
  },
  {
    name: 'profile-80',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'boat',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-80_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'laptop',
      'load',
      'progress',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-81',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'light',
      'bulb',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-81_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'computer',
      'laptop',
      'microphone',
      'speech',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-82',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'light',
      'bulb',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-82_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'car',
      'white'
    ],
  },
  {
    name: 'profile-83',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'print',
      'orange'
    ],
  },
  {
    name: 'profile-83_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'car',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-84',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'fish',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-84_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'fish',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-85',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'fish',
      'dolphin',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-85_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'fish',
      'dolphin',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-86',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'dog',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-86_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'dog',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-87',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'rabbit',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-87_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'rabbit',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-88',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'elephant',
      'outline',
      'orange'
    ],
  },
  {
    name: 'profile-88_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'elephant',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-89',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'bird',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-89_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'bird',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-90',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'monkey',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-90_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'monkey',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-91',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'squirrel',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-91_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'squirrel',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-92',
    bgColor: '#699096',
    category: 'profile',
    isPopular: false,
    tags: [
      'bird',
      'outline',
      'orange',
      'black'
    ],
  },
  {
    name: 'profile-92_0',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'bird',
      'outline',
      'white'
    ],
  },
  {
    name: 'profile-93',
    bgColor: '#035E7F',
    category: 'profile',
    isPopular: false,
    tags: [
      'heart',
      'white',
      'outline'
    ],
  },
  {
    name: 'icon01',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'document'
    ],
  },
  {
    name: 'icon02',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'clip'
    ],
  },
  {
    name: 'icon03',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'power',
      'on',
      'off'
    ],
  },
  {
    name: 'icon04',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'hammer',
      'gavel'
    ],
  },
  {
    name: 'icon05',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'car'
    ],
  },
  {
    name: 'icon10',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'negative',
      'stop'
    ],
  },
  {
    name: 'icon11',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'world'
    ],
  },
  {
    name: 'icon12',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'arrow',
      'download'
    ],
  },
  {
    name: 'icon13',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon14',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'document',
      'pen',
      'edit'
    ],
  },
  {
    name: 'icon15',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'mail',
      'send'
    ],
  },
  {
    name: 'icon16',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'document',
      'folder'
    ],
  },
  {
    name: 'icon17',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'document',
      'folder',
      'arrow'
    ],
  },
  {
    name: 'icon18',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'house'
    ],
  },
  {
    name: 'icon19',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'house'
    ],
  },
  {
    name: 'icon21',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'query'
    ],
  },
  {
    name: 'icon22',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'alert',
      'exclamation',
      'spanish'
    ],
  },
  {
    name: 'icon23',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'alert',
      'exclamation'
    ],
  },
  {
    name: 'icon24',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d'
    ],
  },
  {
    name: 'icon25',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'star'
    ],
  },
  {
    name: 'icon26',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'music',
      'note'
    ],
  },
  {
    name: 'icon27',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'headphones',
      'music'
    ],
  },
  {
    name: 'icon28',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'microphone',
      'sound'
    ],
  },
  {
    name: 'icon29',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'heart'
    ],
  },
  {
    name: 'icon30',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'lock'
    ],
  },
  {
    name: 'icon32',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'key'
    ],
  },
  {
    name: 'icon33',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'print'
    ],
  },
  {
    name: 'icon34',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'recycle'
    ],
  },
  {
    name: 'icon35',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'settings',
      'cog'
    ],
  },
  {
    name: 'icon36',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'basket',
      'shopping'
    ],
  },
  {
    name: 'icon37',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'flag'
    ],
  },
  {
    name: 'icon38',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'search',
      'magnify'
    ],
  },
  {
    name: 'icon39',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'clock',
      'time'
    ],
  },
  {
    name: 'icon40',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'user'
    ],
  },
  {
    name: 'icon41',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'users'
    ],
  },
  {
    name: 'icon42',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'tick',
      'check'
    ],
  },
  {
    name: 'icon43',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'phone',
      'receiver'
    ],
  },
  {
    name: 'icon44',
    bgColor: '',
    category: 'prompt',
    isPopular: false,
    tags: [
      'button',
      'blue',
      '3d',
      'plus',
      'add'
    ],
  },
  {
    name: '100-satisfaction',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'satisfaction',
      'guarantee',
      '100%',
      'colour',
      'blue'
    ],
  },
  {
    name: '100-security',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'security',
      'award',
      'green',
      'colour',
      'satisfaction',
      'guarantee',
      '100%'
    ],
  },
  {
    name: 'best-service',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'best',
      'service',
      'red',
      'blue',
      'satisfaction',
      'colour'
    ],
  },
  {
    name: 'money-back-guarantee',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'money',
      'back',
      'guarantee',
      'flash',
      'orange',
      'satisfaction',
      '100%'
    ],
  },
  {
    name: 'satisfaction-blue',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'flash',
      'satisfaction',
      'colour',
      'blue',
      '100%',
      'guarantee'
    ],
  },
  {
    name: 'satisfaction-gold',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'flash',
      'satisfaction',
      'colour',
      'orange',
      '100%',
      'guarantee'
    ],
  },
  {
    name: 'satisfaction-grey',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'flash',
      'satisfaction',
      'grey',
      'guarantee',
      '100%'
    ],
  },
  {
    name: 'satisfaction-guarante',
    bgColor: '',
    category: 'satisfaction',
    isPopular: false,
    tags: [
      'flash',
      'satisfaction',
      'red',
      'outline',
      'guarantee',
      '100%'
    ],
  },
  {
    name: 'busy',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'no entry',
      'busy',
      'colour',
      '3d'
    ],
  },
  {
    name: 'call-1',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'blue',
      '3d',
      'colour'
    ],
  },
  {
    name: 'call-2',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'pink',
      '3d',
      'colour',
      '3d'
    ],
  },
  {
    name: 'call-3',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'green',
      '3d',
      'colour'
    ],
  },
  {
    name: 'call-telephone',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'blue',
      'outgoing',
      'call',
      '3d'
    ],
  },
  {
    name: 'connect',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'blue',
      'connect',
      'call',
      'colour',
      '3d',
      'tick',
      'check'
    ],
  },
  {
    name: 'contact-2',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'phone',
      'black',
      '3d',
      'old'
    ],
  },
  {
    name: 'contact-telephone',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'contact',
      'phone',
      'black',
      'colour'
    ],
  },
  {
    name: 'end-call',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'receiver',
      'blue',
      '3d'
    ],
  },
  {
    name: 'external',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'ring',
      'ringing',
      'receiver',
      'blue',
      '3d'
    ],
  },
  {
    name: 'iphone-app-developer',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'app',
      'develop',
      'user',
      'man',
      'person',
      'colour',
      '3d'
    ],
  },
  {
    name: 'iphone-application',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'iphone',
      'app',
      'develop',
      'blue',
      'print',
      'colour',
      '3d'
    ],
  },
  {
    name: 'iphone-blue',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'iphone',
      'call',
      'receiver',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'iphone-firewall',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'shield',
      'firewall',
      'call',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'iphone-games',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'game',
      'card',
      'play',
      'ace',
      'spade',
      'black',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'iphone-themes',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'theme',
      'call',
      'paint',
      'brush',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'iphone-yellow',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'call',
      'receiver',
      'colour',
      '3d',
      'orange'
    ],
  },
  {
    name: 'mobile-app-developer',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'app',
      'develop',
      'man',
      'user',
      'person',
      'colour',
      '3d',
      'mobile'
    ],
  },
  {
    name: 'mobile-application',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'app',
      'develop',
      'blue',
      'print',
      'colour',
      '3d',
      'mobile'
    ],
  },
  {
    name: 'mobile-games',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'game',
      'card',
      'play',
      'ace',
      'spade',
      'black',
      'colour',
      '3d',
      'blue',
      'mobile'
    ],
  },
  {
    name: 'mobile-telephone',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'mobile',
      'phone',
      'colour',
      '3d'
    ],
  },
  {
    name: 'pad',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'mobile',
      'phone',
      'colour',
      '3d'
    ],
  },
  {
    name: 'pad-application',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'app',
      'develop',
      'blue',
      'print',
      'colour',
      '3d',
      'mobile'
    ],
  },
  {
    name: 'pad-games',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'game',
      'card',
      'play',
      'ace',
      'spade',
      'black',
      'colour',
      '3d',
      'blue',
      'mobile'
    ],
  },
  {
    name: 'pad-program-developer',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'app',
      'develop',
      'man',
      'user',
      'person',
      'colour',
      '3d',
      'mobile'
    ],
  },
  {
    name: 'pause-call',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'pause',
      'call',
      'contact',
      'receiver',
      'colour',
      '3d'
    ],
  },
  {
    name: 'phone-rescue',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'safe',
      'call',
      'contact',
      'receiver',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'popluar-mobile-telephones',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'yellow',
      'flash',
      'star',
      'tick',
      'check',
      'colour',
      '3d'
    ],
  },
  {
    name: 'record-telephone',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'record',
      'call',
      'contact',
      'receiver',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'sms',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'text',
      'speech',
      'bubble',
      'call',
      'contact',
      'receiver',
      'colour',
      '3d',
      'sms'
    ],
  },
  {
    name: 'talk-time',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'talk',
      'time',
      'receiver',
      'blue',
      'time',
      'clock',
      'colour',
      '3d',
      'blue'
    ],
  },
  {
    name: 'telephone-themes',
    bgColor: '',
    category: 'telephone',
    isPopular: false,
    tags: [
      'phone',
      'theme',
      'call',
      'paint',
      'brush',
      'colour',
      '3d'
    ],
  },
  {
    name: 'ambulance-transport',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'ambulance',
      'colour',
      '3d',
      'vehicle'
    ],
  },
  {
    name: 'bus-transport',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'bus',
      'coach',
      'red',
      '3d',
      'vehicle'
    ],
  },
  {
    name: 'car-transport',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'car',
      'transport',
      'blue',
      '3d',
      'vehicle'
    ],
  },
  {
    name: 'cargo-ship',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'cargo',
      'ship',
      'boat',
      'colour',
      '3d',
      'vehicle'
    ],
  },
  {
    name: 'flight',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'flight',
      'jet',
      'aeroplane',
      'air',
      'plane',
      'colour',
      '3d'
    ],
  },
  {
    name: 'flight-old',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'flight',
      'aeroplane',
      'plane',
      'yellow',
      '3d',
      'airplane'
    ],
  },
  {
    name: 'forklift-truck',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'fork',
      'lift',
      'truck',
      'colour',
      '3d',
      'work'
    ],
  },
  {
    name: 'helicopter',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'helicopter',
      'chopper',
      'colour',
      '3d',
      'flight'
    ],
  },
  {
    name: 'military-vehicle',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'jeep',
      'military',
      'vehicle',
      'green',
      'colour',
      '3d',
      'car'
    ],
  },
  {
    name: 'police-wagon',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'police',
      'car',
      'vehicle',
      'colour',
      '3d'
    ],
  },
  {
    name: 'school-bus',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'school',
      'bus',
      'yellow',
      'colour',
      '3d',
      'vehicle'
    ],
  },
  {
    name: 'scooter',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'scooter',
      'moped',
      'blue',
      'colour',
      '3d'
    ],
  },
  {
    name: 'ship',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'ship',
      'boat',
      'colour',
      '3d'
    ],
  },
  {
    name: 'taxi-transport',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'taxi',
      'car',
      'yellow',
      'colour',
      '3d'
    ],
  },
  {
    name: 'train-transport',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'train',
      'colour',
      '3d'
    ],
  },
  {
    name: 'truck',
    bgColor: '',
    category: 'transport',
    isPopular: false,
    tags: [
      'truck',
      'lorry',
      'van',
      'colour',
      '3d'
    ],
  },
  {
    name: 'icon01-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'blue',
      'colour'
    ],
  },
  {
    name: 'icon02-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour'
    ],
  },
  {
    name: 'icon03-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'purple',
      'colour'
    ],
  },
  {
    name: 'icon04-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'people',
      'green',
      'blue',
      'colour'
    ],
  },
  {
    name: 'icon05-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'people',
      'green',
      'blue',
      'purple',
      'colour'
    ],
  },
  {
    name: 'icon06',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'speech',
      'bubble'
    ],
  },
  {
    name: 'icon07',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'pluss',
      'add',
      'speech',
      'bubble'
    ],
  },
  {
    name: 'icon08',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'blue',
      'colour',
      'computer',
      'screen',
      'tv'
    ],
  },
  {
    name: 'icon09',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'blue',
      'colour',
      'setting',
      'cog'
    ],
  },
  {
    name: 'icon10-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'blue',
      'colour',
      'star'
    ],
  },
  {
    name: 'icon11-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'music',
      'note'
    ],
  },
  {
    name: 'icon12-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'blue',
      'colour',
      'case',
      'business'
    ],
  },
  {
    name: 'icon13-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'search',
      'magnify'
    ],
  },
  {
    name: 'icon14-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'stat',
      'pie',
      'chart'
    ],
  },
  {
    name: 'icon15-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'favourite',
      'heart'
    ],
  },
  {
    name: 'icon16-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'stop'
    ],
  },
  {
    name: 'icon17-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'cross'
    ],
  },
  {
    name: 'icon18-user',
    bgColor: '',
    category: 'user',
    isPopular: false,
    tags: [
      'person',
      'user',
      'man',
      'customer',
      'green',
      'colour',
      'clock',
      'time'
    ],
  },
  {
    name: 'cloudies',
    bgColor: '#6CC4EF',
    category: 'weather',
    isPopular: false,
    tags: [
      'cloud',
      '3d'
    ],
  },
  {
    name: 'cloudy',
    bgColor: '#6CC4EF',
    category: 'weather',
    isPopular: false,
    tags: [
      'cloud',
      '3d'
    ],
  },
  {
    name: 'cloudy-to-sun',
    bgColor: '#6CC4EF',
    category: 'weather',
    isPopular: false,
    tags: [
      'cloud',
      'sun',
      'colour',
      '3d'
    ],
  },
  {
    name: 'drencher',
    bgColor: '#6CC4EF',
    category: 'weather',
    isPopular: false,
    tags: [
      'rain',
      'cloud',
      'colour',
      '3d'
    ],
  },
  {
    name: 'sun',
    bgColor: '#6CC4EF',
    category: 'weather',
    isPopular: false,
    tags: [
      'sun',
      'colour',
      'yellow',
      '3d'
    ],
  },
  {
    name: 'ultraviolet',
    bgColor: '#6CC4EF',
    category: 'weather',
    isPopular: false,
    tags: [
      'sun',
      'colour',
      'yellow',
      '3d',
      'glasses'
    ],
  },
  {
    name: 'weather2',
    bgColor: '#6CC4EF',
    category: 'weather',
    isPopular: false,
    tags: [
      'sun',
      'colour',
      'yellow',
      '3d',
      'cloud'
    ],
  },
];

export default data;
