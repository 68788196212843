import PropTypes from 'prop-types';

const iconType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  isPopular: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
});

export default iconType;
