const data = [{
  id: 'categories',
  label: 'Categories',
  items: [
    { id: 'popular', label: 'Popular' },
    { id: '3d_user', label: '3D User' },
    { id: 'animals', label: 'Animals' },
    { id: 'buttons', label: 'Buttons' },
    { id: 'common', label: 'Common' },
    { id: 'finance', label: 'Finance' },
    { id: 'flags', label: 'Flags' },
    { id: 'food', label: 'Food' },
    { id: 'holiday', label: 'Holidays' },
    { id: 'phone', label: 'Phone' },
    { id: 'medical', label: 'Medical' },
    { id: 'metro', label: 'Metro' },
    { id: 'metro_rounded', label: 'Metro Rounded' },
    { id: 'profile', label: 'Profile' },
    { id: 'prompt', label: 'Prompt' },
    { id: 'satisfaction', label: 'Satisfaction' },
    { id: 'telephone', label: 'Telephone' },
    { id: 'transport', label: 'Transport' },
    { id: 'user', label: 'User' },
    { id: 'weather', label: 'Weather' },
  ],
}];

export default data;
