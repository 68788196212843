import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import Fuse from 'fuse.js';

import 'react-toastify/dist/ReactToastify.css';

import Search from '../components/Search';
import Sidebar from '../components/Sidebar';
import Gallery from '../components/Gallery';

import sidebarCategories from '../data/categories';
import galleryRecords from '../data/gallery';

const findCategoryById = id => sidebarCategories[0].items.find(selection => selection.id === id);
const findRecordsByCategory = id => galleryRecords.filter(record => record.category === id);
const findPopularRecords = () => galleryRecords.filter(record => record.isPopular);
const findRecordsByTerm = (term) => {
  const fuse = new Fuse(galleryRecords, {
    shouldSort: true,
    matchAllTokens: true,
    findAllMatches: true,
    threshold: 0.10,
    location: 0,
    distance: 100,
    maxPatternLength: 18,
    minMatchCharLength: 3,
    keys: ['tags'],
  });

  return fuse.search(term);
};


class App extends Component {
  constructor(props) {
    super(props);

    const selectedCategory = findCategoryById('popular');
    const popularRecords = findPopularRecords();

    this.state = {
      title: selectedCategory.label,
      selectedCategory,
      records: popularRecords,
      searchTerm: '',
    };

    this.onCategorySelected = this.onCategorySelected.bind(this);
    this.onSearchTermChanged = this.onSearchTermChanged.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  onCategorySelected(event, data) {
    const selectedCategory = findCategoryById(data.item.id);
    const title = selectedCategory.label;
    const records = data.item.id === 'popular' ? findPopularRecords() : findRecordsByCategory(data.item.id);
    this.setState({ title, selectedCategory, records });
  }

  onSearchTermChanged(event) {
    const { selectedCategory } = this.state;
    let records = [];
    let title = selectedCategory.label;
    const searchTerm = event.target.value;

    if (searchTerm !== '') {
      title = 'Search Results';
      records = findRecordsByTerm(searchTerm);
    } else {
      records = selectedCategory.id === 'popular' ? findPopularRecords() : findRecordsByCategory(selectedCategory.id);
    }

    this.setState({ title, records, searchTerm });
  }

  clearSearch() {
    this.onSearchTermChanged({
      target: {
        value: '',
      },
    });
  }

  render() {
    const {
      title,
      selectedCategory,
      records,
      searchTerm,
    } = this.state;

    return (
      <main className="slds-grid slds-wrap slds-grow">
        <aside className="slds-grow slds-size_1-of-1 slds-medium-size_4-of-12 slds-large-size_3-of-12 slds-col-rule_right slds-order_2 slds-small-order_2 slds-medium-order_1 slds-large-order_1">
          <Sidebar
            categories={sidebarCategories}
            selectedCategory={selectedCategory.id}
            selectCategory={this.onCategorySelected}
          />
        </aside>
        <div className="slds-grow slds-size_1-of-1 slds-medium-size_8-of-12 slds-large-size_9-of-12 slds-col-rule_right slds-order_1 slds-small-order_1 slds-medium-order_2 slds-large-order_2 slds-p-left_large slds-p-right_large slds-p-bottom_large">
          <header className="slds-grid slds-m-vertical_small">
            <div className="slds-col slds-size_3-of-5">
              <span className="slds-text-heading_large slds-p-vertical_medium">
                {title}
              </span>
            </div>
            <div className="slds-col slds-size_2-of-5">
              <Search
                value={searchTerm}
                filter={this.onSearchTermChanged}
                clear={this.clearSearch}
              />
            </div>
          </header>
          <div className="slds-scoped-notification slds-media slds-media_center slds-scoped-notification_light">
            <div className="slds-media__body">
              <p>
                Browse for icons using the categories on the left, or search for them using keywords.
                {' '}
                Click on the icon you would like to use, which will copy its URL into the clipboard.
                {' '}
                Then select the Graphics tab, create (or update) a graphics record and paste the URL into the
                {' '}
                <b>Image URL</b>
                {' '}
                field.
              </p>
            </div>
          </div>
          <Gallery records={records} />
        </div>
        <ToastContainer
          autoClose={3000}
          hideProgressBar
        />
      </main>
    );
  }
}

export default App;
