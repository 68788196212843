import React from 'react';
import PropTypes from 'prop-types';

import iconType from '../types/icon';
import Icon from './Icon';

const Gallery = ({ records }) => {
  const body = records.map(record => (
    <div key={record.name} className="slds-col slds-size_1-of-4 slds-medium-size_1-of-6 slds-large-size--1-of-8 slds-m-bottom_small">
      <Icon record={record} sizeClass="slds-icon_large" />
    </div>
  ));

  return (
    <div className="slds-grid slds-gutters slds-wrap slds-m-vertical_large">
      {body}
    </div>
  );
};

Gallery.propTypes = {
  records: PropTypes.arrayOf(iconType).isRequired,
};

export default Gallery;
